<template>
  <div>
    <h2 class="seminars-title">Семинары по СУДА и инструменту планирования</h2>
    <h3 class="seminars-info"></h3>
    <div class="seminars-instruction">
      <a href="/doc/seminars_connect_web.docx">Инструкция для Windows</a>
      <a href="/doc/seminars_connect_mobile.mp4">
        Инструкция для Android, Iphone
      </a>
    </div>
    <div class="md-layout">
      <div
        v-for="(seminar, index) of seminars"
        :key="seminar.date"
        class="md-layout-item md-size-100"
      >
        <div
          class="md-seminar-row md-layout"
          :class="[index === 6 ? 'seminar-active' : 'seminar-inactive']"
        >
          <span class="seminar-date">{{ seminar.date }} - 9:30</span>
          <div class="md-size-100">
            <span class="org-list">
              {{ seminar.participants }}
            </span>
          </div>
          <div class="md-size-100">
            <md-button :to="`/seminars/pl/${seminar.stage}`" class="md-info">
              {{ $t('seminars.description') }}
            </md-button>

            <md-button :href="seminar.meetroom" class="md-primary">
              {{ $t('seminars.enter') }}
            </md-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {seminars: []}
  },
  async created() {
    this.seminars = await this.$store.dispatch('GET_SEMINARS')
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/material-dashboard.scss';

.seminars-title {
  text-align: center;
}
.seminars-info {
  color: red;
  text-align: center;
  font-weight: 500;
}
.md-seminar-row {
  border: 1px solid $grey-400;
  background: $grey-300;
  border-radius: 5px;
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  align-items: center;
  > * {
    padding: 10px;
  }
  .seminar-date {
    font-size: 1.4rem;
    font-weight: 500;
    color: $brand-danger;
  }
}
.seminars-instruction {
  display: flex;
  justify-content: space-around;

  font-size: 1.5rem;
  padding: 10px 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
  text-align: center;
}
.org-list {
  font-weight: 500;
  text-align: left;
  display: block;
}
.seminar-active {
  border: 2px solid $brand-success;
  background: $green-100;
}

.seminar-inactive {
  opacity: 0.5;
}

.md-layout-item {
  min-height: 40px;
}

.md-toolbar {
  margin-top: 14px;
  background-color: unset;
}
</style>
